import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×6\\@75% 1RM`}</p>
    <p>{`Pendlay Rows 4×6\\@75% 1RM`}</p>
    <p>{`then, For time:`}</p>
    <p>{`10-Push Press (115/75)(RX+ 135/95)`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`10-Push Jerk (135/95)(RX+ 155/105)`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`10-Split Jerk (155/105)(RX+ 175/115)`}</p>
    <p>{`20-Calorie Row`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      